import { render, staticRenderFns } from "./BusinessSettings.vue?vue&type=template&id=06e1e292&scoped=true"
import script from "./BusinessSettings.vue?vue&type=script&lang=js"
export * from "./BusinessSettings.vue?vue&type=script&lang=js"
import style0 from "./BusinessSettings.vue?vue&type=style&index=0&id=06e1e292&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e1e292",
  null
  
)

export default component.exports