<template>
  <div id="business-settings" class="">
    <div id="profile">
      <h2 class="title is-4">{{ $t('settings.profile') }}</h2>
      <EditableText :title="$t('account.user_name')" v-model='username'/>
    </div>

    <div class="language">
      <LanguageSwitch :inputType="'select'"/>
    </div>

    <div class="change-password section">
      <h3 class="title is-5">{{ $t('account.change_password') }}</h3>
      <form @submit.prevent="changePassword()">
        <b-field :label="$t('account.password')" label-position="on-border">
          <b-input v-model="password"  name="password" type="password" autocomplete="off"/>
        </b-field>
        <b-field :label="$t('account.new_password')" label-position="on-border">
          <b-input v-model="newPassword"  name="password" type="password" autocomplete="off"/>
        </b-field>
        <b-field :label="$t('account.confirm_new_password')" label-position="on-border">
          <b-input v-model="renewPassword"  name="repassword" type="password" autocomplete="off"/>
        </b-field>

        <b-button size="is-large" class="is-primary" @click="changePassword()">{{ $t('account.change_password') }}</b-button>
        <p class="has-text-success" v-show="passwordChangeMessage">{{ $t(passwordChangeMessage) }}</p>
        <p class="has-text-danger" v-show="passwordChangeErrorMessage">{{ $t(passwordChangeErrorMessage) }}</p>
      </form>
    </div>

    <div class="account-delete section">
      <h3 class="title is-5">{{ $t('account.delete_account') }}</h3>
      <b-button size="is-large" class="is-primary" @click="requestAccountDeletion()">{{ $t('account.delete_account') }}</b-button>
      <p class="has-text-success" v-show="accountDeleteMessage">{{ $t(accountDeleteMessage) }}</p>
      <p class="has-text-danger" v-show="accountDeleteErrorMessage">{{ $t(accountDeleteErrorMessage) }}</p>
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue';
import LanguageSwitch from '@/components/LanguageSwitch.vue';

export default{
  name: 'BusinessSettings',
  computed: {
    user(){ return this.$store.getters.user; }
  },
  data(){
    return {
      username: '',
      password: '',
      newPassword: '',
      renewPassword: '',
      passwordChangeMessage: '',
      passwordChangeErrorMessage: '',
      accountDeleteMessage: '',
      accountDeleteErrorMessage: '',
    };
  },
  methods: {
    applyWatchers(){
      this.$watch('username', () => {
        this.$store.dispatch('updateUsername', this.username).then(()=>{
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$t('account.username_saved'),
            position: 'is-bottom',
            type: 'is-success'
          });
        }).catch(err => {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(err),
            position: 'is-bottom',
            type: 'is-danger'
          });
          this.username = this.user.username;
        });
      });
    },


    requestAccountDeletion(){
      this.accountDeleteMessage = '';
      this.accountDeleteErrorMessage = '';
      this.post('/request_delete_account').then(()=>{
        this.accountDeleteMessage = 'account.delete_account_mail';
      }).catch(err => {
        this.accountDeleteErrorMessage = err;
        console.error(err);
      });
    },

    checkPassword(){
      this.passwordChangeErrorMessage = 'errors.account.';
      if(this.password == '' || this.newPassword == ''){
        this.passwordChangeErrorMessage += 'enter_password';
        return false;
      }
      if(this.newPassword != this.renewPassword){
        this.passwordChangeErrorMessage += 'different_password';
        return false;
      }
      this.passwordChangeErrorMessage = '';
      return true;
    },

    changePassword(){
      this.passwordChangeMessage = '';
      if(this.checkPassword()){
        this.post('/change_password', { password: this.password, new_password: this.newPassword }).then(()=>{
          this.passwordChangeMessage = 'account.password_changed';
        }).catch(err => {
          this.passwordChangeErrorMessage = err;
          console.error(err);
        });
      }
    },
  },
  mounted: function(){
    this.username = this.user.username;
    this.settings = this.user.settings;
    this.infos = this.user.infos;
    this.getCoachName();
    this.applyWatchers();
  },
  components: {
    EditableText,
    LanguageSwitch,
  },
};

</script>

<style lang="scss" scoped>
#settings .section{
  border-top: solid 1px;
  padding-bottom: 10px;
}

</style>
