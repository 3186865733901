<template>
  <div class="business-space" v-if="$store.getters.hasBusinessAccess">
    <div class="box">
      <h1 class="title is-2">Espace pro</h1>
      <h3 class="title is-4">{{ $store.getters.user.username }}</h3>
      <p>{{ $store.getters.user.email }}</p>
      <section>
        <router-link to="/business_settings">{{ $t('nav.settings') }}</router-link>
        <b-button type="is-primary" @click="logout">{{ $t('nav.logout') }}</b-button>
      </section>

      <section class="box mt-5">
        <EditableText title="Nom:" v-model="business.name" class="width-400px is-inline-block"/>
        <EditableText title="Adresse:" v-model="business.address" class="width-400px is-inline-block"/>
        <EditableText title="Telephone:" v-model="business.tel" class="width-400px is-inline-block"/>
        <EditableText title="Siret:" v-model="business.siret" class="width-400px is-inline-block"/>
      </section>

      <section class="mt-5">
        <section class="mb-2">
          <b-button @click.native="copySelectedCodes()">Copier les codes selectionnés</b-button>
          <b-button @click.native="downloadSelectedCodes()">Télécharger les codes selectionnés</b-button>
          <b-button @click.native="markSelectedCodesDistributed()">Marquer les codes selectionnés comme distribués</b-button>
        </section>
        <b-table :data="codes" striped bordered narrowed hoverable checkable
          :checked-rows.sync="selectedCodes"> <!--:is-row-checkable="(row) => !row.used && !row.distributed">-->
          <b-table-column field="code" label="Code" v-slot="props" sortable>
            {{ props.row.code }}
          </b-table-column>
          <b-table-column field="type" label="Type" v-slot="props" sortable>
            {{ props.row.type }}
          </b-table-column>
          <b-table-column field="value" label="Valeur" v-slot="props" sortable>
            {{ props.row.value }}j
          </b-table-column>
          <b-table-column field="date_created" label="Créé le" v-slot="props" sortable>
            {{ new Date(props.row.date_created).toLocaleDateString() }}
          </b-table-column>
          <b-table-column field="used" label="Status" v-slot="props" sortable>
            {{ props.row.used ? 'Utilisé' : (props.row.distributed ? 'Distribué' : 'Non utilisé') }}
          </b-table-column>
          <b-table-column field="used" label="Utilisé par" v-slot="props" sortable>
            {{ props.row.used ? props.row.by_username : '' }}
          </b-table-column>
          <b-table-column field="used" label="Le" v-slot="props" sortable>
            {{ props.row.used ? new Date(props.row.date_used).toLocaleDateString() : '' }}
          </b-table-column>
        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import EditableText from '@/components/EditableText.vue';



export default{
  name: 'BusinessSpace',
  computed : {
    isLoggedIn(){ return this.$store.getters.isLoggedIn; },
    user(){ return this.$store.getters.user; },
  },
  data(){
    return {
      codes: [],
      selectedCodes: [],
      business: {
        name: '',
        address: '',
        tel: '',
        siret: '',
      }
    };
  },
  methods: {
    async logout(route='/') {
      if(!this.isLoggedIn)
        return;
      await this.$store.dispatch('logout');
      console.log('new route')
      if(route != this.$router.currentRoute.path)
        this.$router.push(route);
    },

    async loadCodes(){
      try{
        let codes = await this.get('/get_codes');
        this.codes = codes;
        this.generatedCodes = [];
        for(let code of codes){
          if(new Date(code.date_created).toDateString() == new Date().toDateString())
            this.generatedCodes.push(code);
        }
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    //Check if selected codes contains used or distributed codes and warn the user if its the case
    async checkSelectedCodes(){
      if(!this.selectedCodes.length)
        return false;

      let containDistributed = false;
      let containUsed = false;
      for(let code of this.selectedCodes){
        if(code.used)
          containUsed = true;
        if(code.distributed)
          containDistributed = true;
      }

      if(containUsed){
        return await new Promise((resolve, reject) => {
          this.$buefy.dialog.confirm({
            title: 'Votre sélection contient des codes déjà utilisés !',
            message: 'Etes-vous sur de vouloir continuer votre opération ?',
            cancelText: this.$t('general.no'),
            confirmText: this.$t('general.continue'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
      }
      if(containDistributed){
        return await new Promise((resolve, reject) => {
          this.$buefy.dialog.confirm({
            title: 'Votre sélection contient des codes déjà distribués !',
            message: 'Etes-vous sur de vouloir continuer votre opération ?',
            cancelText: this.$t('general.no'),
            confirmText: this.$t('general.continue'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
      }

      return true;
    },

    async copySelectedCodes(){
      if(await this.checkSelectedCodes()){
        let codes = '';
        for(let code of this.selectedCodes){
          codes += code.code + ' ';
        }
        codes = codes.substring(0, codes.length - 1); //remove last space

        navigator.clipboard.writeText(codes);
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('general.copied_to_clipboard'),
          position: 'is-bottom',
          type: 'is-success',
        });
      }
    },

    async downloadSelectedCodes(){
      if(await this.checkSelectedCodes()){
        let codes = '';
        for(let code of this.selectedCodes){
          codes += code.code + '\n';
        }

        var blob = new Blob([codes], {type: 'application/octet-stream'});
        FileSaver.saveAs(blob, 'codes.txt');

        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('general.done'),
          position: 'is-bottom',
          type: 'is-success',
        });
      }
    },

    async markSelectedCodesDistributed(){
      if(await this.checkSelectedCodes()){
        let codes = [];
        for(let code of this.selectedCodes){
          codes.push(code.code);
        }

        try{
          await this.post('/mark_codes_distributed', { codes: codes });

          for(let code of this.selectedCodes){
            code.distributed = true;
          }
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$t('general.done'),
            position: 'is-bottom',
            type: 'is-success',
          });
        }catch(err){
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(err),
            position: 'is-bottom',
            type: 'is-danger'
          });
          console.error(err);
        }
      }
    },



    applyWatchers(){
      this.$watch('business', () => {
        if(!this.business)
          return;
        this.$store.dispatch('updateBusiness', this.business).then(()=>{
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$t('settings.settings_saved'),
            position: 'is-bottom',
            type: 'is-success'
          });
        }).catch(err => {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(err),
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
      }, { deep: true });
    },
  },
  async mounted(){
    this.business = this.user.business;
    this.applyWatchers();
    await this.loadCodes();
  },
  components: {
    EditableText,
  },
}
</script>

<style lang="scss" scoped>
</style>
